import React, { useEffect, useState, useRef } from 'react';
import Table from '../common/Table/Table';
import styled from 'styled-components';
import { LineChart } from '@mui/x-charts/LineChart';
import Container from '@mui/material/Container';
import SEO from '../seo/SEO';

const metadata = {
    title: "Compound Interest Calculator",
    description: "Calculate compound interest and visualize the growth of your investment over time.",
    name: "KalQlator",
    type: "website",
    keywords: "compound interest, investment, calculator, finance",
    creator: "kalqlator"
};

const PRESICION = 3;
const interestConfigName = 'interestConfig';
const GRAPH_MAX_POINTS = 480;

const Paragraph = styled.p`
    margin-top: 10px;
    line-height: 27px;
    font-size: 18px;
    width: max-content;
    max-width: 900px;
    font-family: Arial;
`;
const Label = styled.label`
    margin-top: 10px;
    font-size: 15px;
    font-family: Arial;
    font-weight: bold
`;
const Input = styled.input`
    margin-top: 10px;
    font-size: 15px;
    font-family: Arial;
`;
const Select = styled.select`
    margin-top: 10px;
    font-size: 15px;
    font-family: Arial;
`;
var formatAmount = (amount) => {
    return (parseFloat(amount)).toLocaleString('en-US')

}
var getMonthlyTotal = (months, rateDivider, monthlyAmount, yearlyRate, amount) => {

    let pastAmount = 0, pastTotal = Number(amount), total = 0;
    for (let month = 0; month < Number(months); month++) {
        let newTotal = month == 0 ? pastTotal : pastTotal + Number(monthlyAmount);
        total = Number(newTotal) * ((1 + ((Number(Number(yearlyRate) / rateDivider)) / 100)))//**Number(month+1))
        pastTotal = total
    }
    return total
}

var CompoundInterest = () => {
    let monthRate = [1, 2, 3, 4, 6, 12, 24, 36, 48]//[...Array(12).keys()].map(i => i + 1);
    let mapMonthRateToMonths = { 1: 12, 2: 6, 3: 4, 4: 3, 6: 2, 12: 1, 24: 0.5, 36: 1 / 3, 48: 1 / 4 }

    let years = [...Array(80).keys()].map(i => i + 1);
    const iniYearsRef = useRef(null);
    const iniMonthsRef = useRef(null);
    const refreshGraphRef = useRef(null);
    let count = 0;
    const [refreshGraph, setRefreshGraph] = useState(false);
    const [isGraphRefreshed, setIsGraphRefreshed] = useState(false)
    const [graphXData, setGraphXData] = useState([])
    const [graphYData, setGraphYData] = useState([])


    const [currentConfig, setCurrentConfig] = useState(
        JSON.parse(localStorage.getItem(interestConfigName)) || { iniYears: 5, iniAmount: 10000, iniMonths: 12, iniRate: 30, iniMonthlyAmount: 100 }
    )


    useEffect(() => {
        iniYearsRef.current.value = '' + currentConfig.iniYears
        iniMonthsRef.current.value = '' + currentConfig.iniMonths
    }, []);

    useEffect(() => {
        localStorage.setItem(interestConfigName, JSON.stringify(currentConfig))
    }, [currentConfig]);

    useEffect(() => {
        if (isGraphRefreshed) {
            let xData = sampleArray(gMontlyAxisArray, getSampleStep(GRAPH_MAX_POINTS));
            let yData = sampleArray(stringToFloatArray(amountsByMonth, "totalGain"), getSampleStep(GRAPH_MAX_POINTS));
            setGraphXData(xData)
            setGraphYData(yData)
            setIsGraphRefreshed(true)
        }
    }, [isGraphRefreshed])
    useEffect(() => {
        setIsGraphRefreshed(false)
    }, [currentConfig])

    let total = Number(currentConfig.iniAmount) * ((1 + (Number(currentConfig.iniRate / currentConfig.iniMonths) / 100)) ** Number(currentConfig.iniMonths))
    total = total.toFixed(PRESICION)
    let amountsByMonth = [...Array(Number(currentConfig.iniYears * currentConfig.iniMonths)).keys()].map((month, index) => {
        // let monthTotal = Number(iniAmount) * ((1 + (Number(iniRate)/100))**Number(month+1))
        let iniTotal = month + 1 == 1 ? currentConfig.iniAmount : getMonthlyTotal(month, currentConfig.iniMonths, currentConfig.iniMonthlyAmount, currentConfig.iniRate, currentConfig.iniAmount).toFixed(PRESICION).toString()
        let monthTotal = getMonthlyTotal(month + 1, currentConfig.iniMonths, currentConfig.iniMonthlyAmount, currentConfig.iniRate, currentConfig.iniAmount)
        monthTotal = monthTotal.toFixed(PRESICION)
        let totalContribution = Number(currentConfig.iniAmount) + Number(currentConfig.iniMonthlyAmount) * index
        let totalGain = monthTotal - totalContribution
        let totalPercentage = formatAmount(((Number(totalGain) / Number(totalContribution)) * 100).toFixed(PRESICION))
        // let iniMonthlyAmount = index==0?0:formatAmount(iniMonthlyAmount.toString())
        let iniMonthlyAmountNumber = index == 0 ? "0" : currentConfig.iniMonthlyAmount
        let yearNumber = Math.trunc((index) / currentConfig.iniMonths) + 1
        let monthNumber = ((Math.trunc(index) + 1) * mapMonthRateToMonths[currentConfig.iniMonths]).toFixed(1)
        totalGain = totalGain.toFixed(PRESICION)

        iniTotal = formatAmount(iniTotal)
        monthTotal = formatAmount(monthTotal)
        totalContribution = formatAmount(totalContribution)
        totalGain = formatAmount(totalGain)
        iniMonthlyAmountNumber = formatAmount(iniMonthlyAmountNumber)

        return {
            yearNumber, monthNumber, iniTotal, iniMonthlyAmountNumber, totalContribution, monthTotal, totalGain, totalPercentage
        }
    });

    let tableData = {
        header: ["Year", "Month", "Initial Amount", "Periodic Contribution", "Total Contribution", "Final Amount", "Total Gain", "TotalGain[%]"],
        rows: amountsByMonth
    }

    // Graph
    let gMontlyAxisArray = amountsByMonth.map((month, index) => {
        return parseFloat(month.monthNumber)
    })
    let gYearAxisArray = amountsByMonth.map((month, index) => {
        return parseFloat(month.yearNumber)
    })
    let gMonthTotalArray = amountsByMonth.map((month, index) => {
        return parseFloat(month.monthTotal)
    })
    let gMonthlyTotalContributionlArray = amountsByMonth.map((month, index) => {
        return parseFloat(month.iniTotal)
    })
    let stringToFloatArray = (array, key) => {
        console.log(array)
        return array.map((value, index) => {
            return parseFloat(value[key].replace(/,/g, ''))
        })
    }
    let stringToIntArray = (array, key) => {
        console.log(array)
        return array.map((value, index) => {
            return parseInt(value[key].replace(/,/g, ''))
        })
    }


    let sampleArray = (data, step) => {

        if (step === 0 || data.length === 0 || data.length < step) {
            return [];
        }
        let sample = [];
        for (let i = 0; i < data.length; i += step) {
            if (i > data.length - 1) {
                break;
            } else {
                sample.push(data[i]);
            }
        }
        return sample;
    };
    let getSampleStep = (maxPoints) => {
        return Math.ceil(amountsByMonth.length / maxPoints);
    }

    return <div>
        <SEO
            title={metadata.title}
            description={metadata.description}
            keywords={metadata.keywords}
            name={metadata.name}
            type={metadata.type}
            creator={metadata.creator}
        />
        <h1>Compound Interest  </h1>
        <Paragraph >
            This compound insterest tool helps you to visualize the  amount of money
            you can get in period of investement(montly, quartely, half a year, etc) if you have a your money
            working at an interest rate(<b>Yearly Rate</b>) over the <b>Years</b>. This tool also allows you to set a <b>montly contribution</b> so
            it can be considered in next periods of investment.Other parameter you can set is the number of <b> reinvestments per year</b>,
            that can be 1, 2, 3, or 4, meaning <i>Every year</i>, <i>Every 6 months</i>, <i>Every 4 months</i> and <i>Every 3 months</i>, accordingdly.

        </Paragraph>

        <div >
            <Label htmlFor="initial-amount">Initial amount: </Label>
            <Input type="number" maxLength="10" value={currentConfig.iniAmount} name="initial-month" id="initial-amount" onChange={(e) => setCurrentConfig({ ...currentConfig, iniAmount: e.target.value })} />
            {/* <br /> */} &nbsp;
            <Label htmlFor="initial-rate">Yearly Rate: </Label>
            <Input type="number" maxLength="3" value={currentConfig.iniRate} name="initial-rate" id="initial-rate" onChange={(e) => setCurrentConfig({ ...currentConfig, iniRate: e.target.value })} />
            <br />
            <Label htmlFor="monthly-amount">Contribution every reinvestment period: </Label>
            <Input type="number" maxLength="10" value={currentConfig.iniMonthlyAmount} name="monthly-amount" id="initial-monthly-amount" onChange={(e) => setCurrentConfig({ ...currentConfig, iniMonthlyAmount: e.target.value })} />
            {/* <br /> */}
        </div>

        <Label htmlFor="initial-months">Reinvestments per year: </Label>
        <Select ref={iniMonthsRef} onChange={(e) => setCurrentConfig({ ...currentConfig, iniMonths: e.target.value })} name="initial-months">
            {monthRate.map((value, index) => {
                return <option value={value}>{value}</option>
            })
            }
        </Select>  &nbsp;
        {/* <br /> */}
        <Label htmlFor="initial-years">Years: </Label>
        <Select ref={iniYearsRef} onChange={(e) => setCurrentConfig({ ...currentConfig, iniYears: e.target.value })} name="initial-years" defaultValue={' ' + currentConfig.iniYears}>
            {years.map((value, index) => {
                return <option value={value}>{value}</option>
            })
            }

        </Select>
        <br />

        <LineChart

            xAxis={[{
                data: sampleArray(gMontlyAxisArray, getSampleStep(GRAPH_MAX_POINTS)),
                label: 'Months',
            },
            ]}
            series={[
                {
                    data: sampleArray(stringToFloatArray(amountsByMonth, "totalGain"), getSampleStep(GRAPH_MAX_POINTS)),
                    label: 'Total Gain',
                    showMark: false,
                    color: 'blue'
                },
                {
                    data: sampleArray(stringToFloatArray(amountsByMonth, "totalContribution"), getSampleStep(GRAPH_MAX_POINTS)),
                    label: 'Total Investment',
                    showMark: false,
                    color: 'cyan'
                },
                {
                    data: sampleArray(stringToFloatArray(amountsByMonth, "monthTotal"), getSampleStep(GRAPH_MAX_POINTS)),
                    label: 'Final Amount',
                    showMark: false,
                    color: 'red'

                },
                {
                    data: sampleArray(stringToIntArray(amountsByMonth, "totalPercentage"), getSampleStep(GRAPH_MAX_POINTS)),
                    label: '% Gain',
                    showMark: false,
                    color: 'green'

                },

            ]}
            height={500}
            width={750}
            margin={{ left: 150, right: 30, top: 30, bottom: 60 }}
            grid={{ vertical: true, horizontal: true }}
            skipAnimation={true}
            order={['Total Gain', 'Final Amount', 'Total Investment', '% Gain']}
        // axisHighlight={{x: 'line', y: 'line' }}


        />
        {/* <button onClick={() => setIsGraphRefreshed(true)} disabled={isGraphRefreshed}>Refresh Graph</button> */}
        <hr />

        {/* {Table(tableData, null, currentConfig.iniMonths)} */}
    </div>
}

export default CompoundInterest;