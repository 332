import React from "react";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SEO from '../seo/SEO';

const metadata = {
    title: "JSON Validator",
    description: "Validate JSON strings.",
    name: "JsonValidator",
    type: "website",
    keywords: "JSON, validator, string",
    creator: "kalqlator"
};

const TextArea = styled.textarea`
    background-color: beige;
    border: 1px solid #000000;
    padding: 10px;
    border-radius: 5px;
    display: block;
    // width:400px;
    // client-width: 400px;
    width: fit-content;
    min-width: 400px;
    min-height: 100px;
    max-height: 500px;


    resize: none;
    font-size: 14px;
    // overflow-wrap: 
`;

const Div = styled.div`
    display: grid;
    grid-template-columns: 500px;
    grid-template-rows: auto;
    column-gap: 10px;
    row-gap: 15px;
    client-width: 400px;
    // min-width: 400px;
    // min-height: 100px;
    // overflow: auto;
`;
const Input = styled.input`
    font-size: 16px;
`;

const JsonValidator = () => {
    const [input, setInput] = useState("");
    const [json, setJson] = useState("");
    const [error, setError] = useState("");

    const textAreaRef = useRef(null);

    useEffect(() => {
        setHeight(textAreaRef);
        setJson("");
    }, [input])

    useEffect(() => {
        setHeight(textAreaRef);
    }, [json])

    const setHeight = (textAreaRef) => {
        let textArea = textAreaRef.current;
        // let fontSize =  window.getComputedStyle(textArea , null).getPropertyValue('font-size');

        let lines = textArea.value.split("\n");
        let lineCount = lines.length;
        let lineHeight = 18;
        let height = lineHeight * lineCount;
        textArea.style.height = height + "px";
        // textArea.scrollTop = 0;
        // textArea.style.overflow = "hidden";
    };

    const handleInput = (e) => {
        setInput(e.target.value);
    };

    const handleValidate = () => {
        try {
            let jsonParsed = JSON.parse(input);
            setJson(JSON.stringify(jsonParsed, null, 2));
            setError("");
        } catch (e) {
            setJson("");

            setError(e.message);
        }
    };

    return (
        <Div>
            <SEO
                title={metadata.title}
                description={metadata.description}
                keywords={metadata.keywords}
                name={metadata.name}
                type={metadata.type}
                creator={metadata.creator}
            />
            <h1>JSON Validator</h1>
            Validate JSON strings.
            <label htmlFor="json-string">JSON string:</label>
            <TextArea name="json-string" ref={textAreaRef} value={input} onChange={handleInput} />
            <Button
                variant='contained'
                color={"primary"}
                onClick={handleValidate}
                style={{ width: "100px" }}
            >
                Validate
            </Button>
            {json != "" || error ?
                <Box sx={{
                    backgroundColor: error == "" ? "#4EB2E4" : "#f16041",
                    border: 1,
                    borderColor: "black",
                    borderRadius: 2,
                    padding: "10px",
                    width: "400px",
                    fontSize: "14px",
                    maxHeight: "200px",
                    overflow: "auto",
                }}
                >
                    <p>{error}</p>
                    <p ><pre style={{ whiteSpace: "pre-wrap" }}>{json}</pre></p>
                </Box>
                : ""
            }
        </Div>
    );
};
//Get icon from material-ui for a validator
//https://mui.com/components/material-icons/
export default JsonValidator;
