import React from "react";
const style = {
    position: "absolute", 
    bottom: 0, 
    left: 0, 
    marginBottom:0, 
    fontSize:14, 
    color:"gray", 
    }
const Version = (props) => {
    return (
        <>
            <p style={style}>v{props.version}</p>
        </>
     );
}

export default Version;