import React from 'react';
import {Card, CardContent, CardActions} from '@mui/material';
import {TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#8aacc8",
      color: theme.palette.common.black,
      fontSize: 14,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 20,
      fontWeight: 'bold'
    }
  }));
  
const getTable = (content, iniMonths) => {
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        [`&:nth-of-type(${iniMonths}n)`]: {
          backgroundColor: "#bbdefb",//theme.palette.common.black,
          color: theme.palette.common.black
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
    return (
        <TableContainer component={Paper} sx={{ marginTop:'20px', minWidth: 500, width:'max-content',maxWidth:1200 }} style={{backgroundColor: '#eeffff'}}>
            <Table size="small" aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {content.header.map((e, index) => {  
                            if(index===0){
                                return <StyledTableCell>{e}</StyledTableCell>;
                            }
                            else{
                                return <StyledTableCell align="right">{e}</StyledTableCell>;
                                
                            }
                         } )}
                    </TableRow>
                </TableHead>
                    {content.rows.map((row) => {  
                        return <StyledTableRow>
                            {Object.keys(row).map((cellName, index)=>{
                                if(index===0){
                                    return <StyledTableCell sx={{fontWeight:'bold', fontSize: 16, fontFamily: 'sans-serif'}}>{row[cellName]}</StyledTableCell>;
                                }
                                else{
                                    return <StyledTableCell sx={{fontWeight:'bold', fontSize: 16, fontFamily: 'sans-serif'}} align="right">{row[cellName]}</StyledTableCell>;
                                }
                            })
                            }
                        </StyledTableRow>;
                    })
                    }
            </Table>
        </TableContainer>
    );
}

const getCardWithTable = (content, action, iniMonths) => {
        return getTable(content,iniMonths)
}

export default getCardWithTable;