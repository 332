import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, keywords,  name, type, creator }) => {
    return (
        <Helmet>
            { /* Metadata tags */}
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="author" content={name} />
            { /* Facebook tags */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            
            { /* Twitter tags */}
            <meta name="twitter:creator" content={creator} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
        </Helmet>
    )
}
export default SEO;