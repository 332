import React from "react";
import styled from "styled-components";
import SEO from '../seo/SEO';

const metadata = {
    title: "Years to Double Money",
    description: "Get the amount of years it will take to double your money based on interest rate.",
    name: "DoubleCapital",
    type: "website",
    keywords: "double money, interest rate, finance",
    creator: "kalqlator"
};

const Div = styled.div`
    display: grid;
    grid-template-columns: 300px ;
    grid-template-rows: 100px ;
    column-gap: 10px;
    row-gap: 15px;
`;

const DoubleCapital = () => {
    const [interestRate, setInterestRate] = React.useState(0);
    const [unit, setUnit] = React.useState('Month');
    const [result, setResult] = React.useState(0);

    const calculate = () => {
        const result = parseFloat((72 / interestRate).toFixed(1));
        setResult(Number.isInteger(result) ? parseInt(result) : result);
    };

    const handleUnit = (e) => {
        setUnit(e.target.value);
    };

    return (
        <Div>
            <SEO
                title={metadata.title}
                description={metadata.description}
                keywords={metadata.keywords}
                name={metadata.name}
                type={metadata.type}
                creator={metadata.creator}
            />
            <h1>Years to double money</h1>
            <p>Get the amount of years will it take to double your money based
                on interest rate. Based on 72 finance rule.
            </p>
            <select onChange={handleUnit}>
                <option value="Day">Daily</option>
                <option value="Month" selected>Monthly</option>
                <option value="Year">Yearly</option>
            </select>
            <label>Interest Rate:</label>
            <input
                type="number"
                value={interestRate}
                onChange={(e) => setInterestRate(e.target.value)}
            />
            <button onClick={calculate}>Calculate</button>
            <p>You will double your money in {result} {unit}{result === 1 ? "" : "s"}</p>
        </Div>
    );
};

export default DoubleCapital;


