import * as React from 'react';
import { createRef, useEffect } from 'react';
import getCard from '../common/Card/Card';
import styled from 'styled-components';
import SEO from '../seo/SEO';

const metadata = {
    title: "Temperature Converter",
    description: "Convert temperatures between Celsius and Fahrenheit.",
    name: "Temperature Converter",
    type: "website",
    keywords: "temperature, converter, Celsius, Fahrenheit",
    creator: "kalqlator"
};

const divStyle = {
    display: "inline-block",
}
const Label = styled.label`
    font-size: 16px;
    font-family: Arial;
    justify-self: end;
    align-self: center;
    fotn-weight: bold;
`;
const Div = styled.div`
    display: grid;
    grid-template-columns: 100px 100px 50px;
    grid-template-rows: auto auto auto; 
    column-gap: 10px;
    row-gap: 15px;
`;
const Input = styled.input`
    font-size: 16px;
    font-family: Arial;
    font-weight: bold;
    alig-self: start;
    
`;
const Pdeg = styled.p`
    display: initial;
    font-size: 16px;
    font-family: Arial;
    align-self: center;
    margin: 0;
`;

export default function CelsiusToFarenheit() {
    let celsiusRef = createRef();
    let farenheitRef = createRef();

    const getFarenheit = (celsius) => {
        return parseFloat((parseFloat(celsius) * 9) / 5 + 32).toFixed(1);
    }
    const getCelsius = (farenheit) => {
        return parseFloat((parseFloat(farenheit) - 32) * 5 / 9).toFixed(1);
    }

    useEffect(() => {
        //Read from localstorage
        let storage = JSON.parse(localStorage.getItem('calculator'));
        if (storage) {
            celsiusRef.current.value = parseFloat(storage.celsius);
            farenheitRef.current.value = parseFloat(storage.farenheit);
        }
    }, []);

    const handleFarenheit = (e) => {
        console.log(e.target.value);
        if (e.target.value == undefined || e.target.value == "") {
            celsiusRef.current.value = 0;
            return
        }
        let celsius = getCelsius(e.target.value);
        celsiusRef.current.value = celsius;
        //save to localstorage
        let objToSave = { celsius: celsius, farenheit: farenheitRef.current.value }
        localStorage.setItem('calculator', JSON.stringify(objToSave))

    }
    const handleCelsius = (e) => {
        if (e.target.value == undefined || e.target.value == "") {
            farenheitRef.current.value = 0;
            return
        }
        let farenheit = getFarenheit(e.target.value);
        farenheitRef.current.value = farenheit;
        //save to localstorage
        let objToSave = { farenheit: farenheit, celsius: celsiusRef.current.value }
        localStorage.setItem('calculator', JSON.stringify(objToSave));
    }


    return (
        getCard(
            <>
                <SEO
                    title={metadata.title}
                    description={metadata.description}
                    keywords={metadata.keywords}
                    name={metadata.name}
                    type={metadata.type}
                    creator={metadata.creator}
                />
                <div style={divStyle} >
                    <h2>Temperature Converter</h2>
                    <Div>

                        <Label htmlFor="celcius">Celsius:</Label>
                        <Input type="number" ref={celsiusRef} name="celsius" id="1" onChange={handleCelsius} defaultValue="0" /> <Pdeg>&deg;C</Pdeg>
                        <Label htmlFor="farenheit">Farenheit:</Label>
                        <Input type="number" ref={farenheitRef} name="farenheit" id="2" onChange={handleFarenheit} defaultValue="0" /> <Pdeg>&deg;F</Pdeg>
                    </Div>
                </div>
            </>
            , <></>
        )
    );
}
