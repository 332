import React, { useState, useRef } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import getCard from '../common/Card/Card';
import Backspace from '@mui/icons-material/Backspace';
import { ShapeLineRounded } from '@mui/icons-material';
import { BorderAllRounded } from '@material-ui/icons';
import { styled } from '@mui/system';
import { grey, blue } from '@mui/material/colors';
import SEO from '../seo/SEO';

const metadata = {
    title: "Calculator",
    description: "Perform basic arithmetic calculations with this simple calculator.",
    name: "Calculator",
    type: "website",
    keywords: "calculator, arithmetic, calculations",
    creator: "kalqlator",
};

const divStyle = {
    display: "inline-block"
}
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TextareaAutosizeStyled = styled(TextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    resize: none;
    width: 100%;
    font-family: 'Arial','Courier New', Courier, monospace;
    font-size: 22px;
    font-weight: 400; /* Increase font weight for boldness */
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    margin-bottom: 10px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    text-transform: uppercase; /* Make the text uppercase */
    letter-spacing: 1px; /* Increase letter spacing for a squared look */
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);
const Calculator = () => {
    let symbols = ["DEL", "AC", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "+", "-", "=", "*", "/", "(", ")", "."]

    let [operation, setOperation] = useState([]);
    let [showedResult, setShowedResult] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    let getResult = (operations) => {
        return operations.join();
    }
    const handleOperation = (key) => {
        //modify to when the = is pressed, the result is shown, and if
        // a operation symbol is pressed, the result taken is used as the first number

        // console.log(key)
        // console.lo([...operation, key])
        // if(showedResult===true){
        //     setShowedResult(false)
        //     setOperation([])
        // }
        if (key === "=") {
            try {
                console.log(eval(operation.join('')))
                operation.forEach((item) => {
                    if (!symbols.includes(item)) {
                        throw 'Error: invalid expression'
                    }
                })
                let result = eval(operation.join('')).toString().split('')

                setOperation(result)
            }
            catch (error) {
                setOpen(true)
                setOperation([])
            }
            setShowedResult(true)
        }
        else if (key === "AC") {
            setOperation([])
        }
        else if (key === "DEL") {
            operation.pop()
            setOperation([...operation])
        }
        else {
            setOperation([...operation, key])
        }

    }

    return (

        getCard(
            <>
                <SEO
                    title={metadata.title}
                    description={metadata.description}
                    keywords={metadata.keywords}
                    name={metadata.name}
                    type={metadata.type}
                    creator={metadata.creator}
                />
                <div style={divStyle}>
                    <h2> Calculator</h2>
                    <TextareaAutosizeStyled value={operation.join('')} />
                    <Stack direction="row" spacing={2}>
                        <Grid container spacing={1}>
                            <Grid container item xs={12} spacing={3}>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("(") }}> {"("}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation(")") }}> {")"}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" color="secondary" onClick={() => { handleOperation("AC") }}> {"AC"}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("DEL") }}> <Backspace /></Button>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3}>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("7") }}> {7}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("8") }}> {8}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("9") }}> {9}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("/") }}> {"/"}</Button>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3}>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("4") }}> {4}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("5") }}> {5}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("6") }}> {6}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("*") }}> {"*"}</Button>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3}>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("1") }}> {1}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("2") }}> {2}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("3") }}> {3}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("-") }}> {"-"}</Button>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3}>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("0") }}> {0}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation(".") }}> {"."}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" color="secondary" onClick={() => { handleOperation("=") }}> {"="}</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={() => { handleOperation("+") }}> {"+"}</Button>
                                </Grid>
                            </Grid>
                        </Grid>


                    </Stack>
                    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error">
                            Calculator: Input Invalid!
                        </Alert>
                    </Snackbar>
                </div>
            </>
            , <></>)

    )
}

export default Calculator;