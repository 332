import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import SEO from '../seo/SEO';

const metadata = {
    title: "Sum Tool",
    description: "This tool allows you to sum numbers in text and set the delimiter.",
    name: "SumWithDelimiter",
    type: "website",
    keywords: "sum, tool, numbers, delimiter",
    creator: "kalqlator"
};

const Div = styled.div`
    display: grid;
    grid-template-columns: 300px ;
    grid-template-rows: 100px ;
    column-gap: 10px;
    row-gap: 15px;
`;

const TextArea = styled.textarea`
    font-size: 16px;
    height: 100px;
`;

const Input = styled.input`
    font-size: 16px;
    width: 100px;
    font-weight: bold;
`;

const SumWithDelimiter = () => {
    const [sum, setSum] = useState(10);
    const [input, setInput] = useState("");
    const [delimiter, setDelimiter] = useState(",");

    const handleInput = (e) => {
        setInput(removeMultipleSpaces(e.target.value));
    }

    const handleDelimiter = (e) => {
        setDelimiter(e.target.value);
    }

    const removeMultipleSpaces = (str) => {
        return str.replace(/\s+/g, ' ');
    };

    // const trim = (str) => {
    //     return str.replace(/^\s+|\s+$/g, '');
    // };

    useEffect(() => {
        let newSum = 0;
        if (input != "") {
            let numbers = input.split(delimiter);
            numbers.forEach(number => {
                newSum += parseFloat(number);
            });
        }
        setSum(newSum.toFixed(2));
    }, [input, delimiter]);

    return (
        <Div>
            <SEO
                title={metadata.title}
                description={metadata.description}
                keywords={metadata.keywords}
                name={metadata.name}
                type={metadata.type}
                creator={metadata.creator}
            />
            <h1>Sum Tool</h1>
            <p>This tool allow you to sum numbers in text and set what the delimiter is: whitepspace, comma, dot, semicolon, etc.</p>
            <label htmlFor="input-text">Numbers separated by delimiter:</label>
            <TextArea name='input-text' type="text" value={input} onChange={handleInput} placeholder='Ex. 5,3,2' />
            <label htmlFor="delimiter">Delimiter:</label>
            <Input name='delimiter' type="text" value={delimiter} onChange={handleDelimiter} defaultValue=',' />
            <p>Sum: {sum}</p>
        </Div>
    );
}

export default SumWithDelimiter;