import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import JsonValidator from './modules/jsonValidator/jsonValidator';
import CompoundInterest from './modules/compoundInterest/compoundInterest';
import CelsiusToFarenheit from './modules/celsiusToFarenheit/celsiusToFarenheit';
import Calculator from './modules/Calculator/Calculator';
import SumWithDelimiter from './modules/SumWithDelimiter/SumWithDelimiter';
import DoubleCapital from './modules/doubleCapital/doubleCapital';
import Navbar from './modules/navbar/Navbar';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <StrictMode>
    <Router>
      <h1>KalQLator</h1>
      <Navbar content={
      <Routes>
        <Route exact path={"/"} element={<Calculator />} />
        <Route exact path={"/json-validator"} element={<JsonValidator/>} />
        <Route exact path={"/sum-with-delimiter"} element={<SumWithDelimiter/>} />
        <Route exact path={"/double-capital"} element={<DoubleCapital/>} />
        <Route exact path={"/celcius-to-farenheit"} element={<CelsiusToFarenheit />} />
        <Route exact path={"/compund-interest"} element={<CompoundInterest />} /> 
        {/* <JsonValidator/> */}
      </Routes>
      }/>
    </Router>
  </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
