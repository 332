import * as React from "react";
import { SvgIcon } from "@material-ui/core";

const TimesTwo = (props) => {
    return (
        <SvgIcon
            width="100%"
            height="100%"
            viewBox="0 0 210 210"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            // style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
        >
            <g transform="matrix(1.00568,0,0,1.0058,-1.3374,-0.608005)">
                <path d="M209.964,18.59C209.964,8.838 202.045,0.921 192.292,0.921L18.821,0.921C9.068,0.921 1.15,8.838 1.15,18.59L1.15,192.04C1.15,201.792 9.068,209.71 18.821,209.71L192.292,209.71C202.045,209.71 209.964,201.792 209.964,192.04L209.964,18.59Z"  />
            </g>
            <g transform="matrix(3.70509,0,0,3.70509,-171.115,-50.2454)">
                <g transform="matrix(50,0,0,50,102.306,59.0233)">
                </g>
                <text x="49.499px" y="59.023px" style={{fontFamily:'ArialMT',fontSize:'45px',fill:'white'}}>x2</text>
            </g>
        </SvgIcon>
    );
}

export default TimesTwo;