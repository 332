import { BorderAllRounded } from "@mui/icons-material";
import React from "react";
const Card = {
    padding:'20px',
    margin:'20px',
    width: '2px',
    backgroundColor: '#3899CF',
    borderRadius: '20px',
}

const presentationalStyles = {
    Card: Card,
}

export default presentationalStyles;