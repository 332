import React from 'react';
import {Card, CardContent, CardActions} from '@mui/material';

import cardStyles from './cardStyles';

const getCard = (content, action) => {
    return <Card style={cardStyles.Card} sx={{ minWidth: 300, maxWidth: 50 }}>
        <CardContent>
            {content}
        </CardContent>
        <CardActions>
            {action}
        </CardActions>
    </Card>;

}

export default getCard;